.calendar_type {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    font-size: 0.9rem;
    color: var(--dark);
}

.calendar_type_background {
    background-color: var(--yellow);
}

.calendar_select {
    height: 100%;
    width: 100%;
}

.dropdown-calendar.show {
    position: fixed !important;
    transform: unset !important;
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: row-reverse !important;
    background: #00000091 !important;
}
.dropdown-item:hover {
    background: transparent !important;
}

.dropdown-item:active {
    background: transparent !important;
    height: 100% !important;
}

.dropdown-item {
    height: auto !important;
    width: 16.66666667% !important; // see boostrap source code
    display: flex !important;
    justify-content: center !important;
    padding: 0.25em 0 !important;
}

// Crappy, do it better...
.calendar_select_drop_padding {
    padding-top: 6.2rem !important;
}
// Crappy again, do it better...
.calendar_item_drop_padding_first {
}

// Crappy again, do it better...
.calendar_item_drop_padding_second {
  padding-top: 2.9rem !important;

}

.custom-calendar-month .pika-table {
    display: none !important;
}
.custom-calendar-year {
    .pika-table {
        display: none !important;
    }
    .pika-label:nth-child(1) {
        display: none !important;
    }
}

.custom-calendar-theme {
    border: unset !important;
    display: flex !important;

    .is-outside-current-month.is-selection-disabled .pika-day {
        color: var(--greyOpacity) !important;
        opacity: .1 !important;
    }
    .pika-lendar {
        width: 100% !important;
    }
    .pika-day {
        font-weight: bold !important;
    }

    .pika-prev, .pika-next {
        background-size: cover;
    }

    .pika-label:nth-child(1) {
        background: transparent !important;
    }

    .pika-label:nth-child(1)::after {
        content: '\25be' !important;
        padding: 0 1rem;
    }
    .pika-label:nth-child(2):after {
        content: '\25be' !important;
        padding: 0 1rem;
    }

    .pika-label:nth-child(2) {
        background: transparent !important;
    }

    .pika-select.pika-select-year {
        // display: none !important;
    }

    .pika-button {
        text-align: center !important;
        display: block !important;
        width: 45px !important;
        height: 45px !important;
        font-size: 1rem;
        background: rgba($color: #000000, $alpha: .05) !important;
        margin: .5px;
    }
    .pika-button:hover {
        background-color: var(--greyOpacity) !important;
    }
    .is-today .pika-button {
        color: var(--dark) !important;
    }
    .is-selected .pika-button {
        background-color: var(--blueOpacity) !important;
        color: var(--dark) !important;
        border: none !important;
        box-shadow: none !important;
    }
}
