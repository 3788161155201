.input_default{
  padding: 0.75rem 0.875rem !important;
  border: 0.063rem solid var(--dark) !important;
  border-radius: var(--radiusSecondary) !important;
  background-color: transparent !important;
}

.btn{
    background-color: var(--dark) !important;
    color: var(--light) !important;
    border-radius: var(--radiusSecondary) !important;
    padding: 0.5rem 0.875rem !important;
    font-size: .9rem  !important;
}

.btn-return{
  background-color: var(--whiteOpcaity) !important;
  color: var(--dark) !important;
  padding: 0.5rem 0.875rem !important;
  border-radius: var(--radiusSecondary) !important;
  border: none;
  font-size: .9rem  !important;
}



.btn-light{
  background-color: transparent !important;
  color: var(--dark) !important;
  padding: 0.5rem 0.875rem !important;
  border-radius: var(--radiusSecondary) !important;
  border: none !important;
  font-size: .9rem  !important;
}

.btn-alert{
  background-color: transparent !important;
  color: var(--dark) !important;
  padding: 0.5rem 0.875rem !important;
  border-radius: var(--radiusSecondary) !important;
  border: none !important;
  font-size: .9rem  !important;
}



.form-control:focus {
  box-shadow: unset !important;
}

.btn-calandar{
  width: 40px;
}

.btn-transparant{
  background-color: transparent;
  border: none;
}


/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid black;
  -webkit-text-fill-color: var(--dark);
  transition: background-color 5000s ease-in-out 0s;
}
