:root {
    .dark_theme {
        // color
        --dark: #f3f3f3;
        --darkOpacity: #f3f3f3cc;
        --grey: #5d5d63;
        --greyOpacity: #66666646;
        --blueLight: #72adf2;
        --whiteOpcaity: #ffffffcc;
        --light: #171717;
        --lightOpacity: #000000cc;
        --blue: #f3f3f3;
        --blueOpacity: #024f5d30;
        --yellow: #e3a719;
        --red: #e05a49;
        --redOpacity: #e05a4980;
        --green: #34a76e;
        --greenOpacity: #34a76e80;
        --orange: #E77F3E;

        // opacity
        --opacityPrimary: 0.7;
        --opacitySecondary: 0.5;
        --opacityTertiary: 0.3;

        // radius
        --radiusPrimary: 20px;
        --radiusSecondary: 14px;
        --radiusTertiary: 7px;

        // shadow
        --shadowPrimary: 1px 1px 8px rgba(0, 0, 0, 0.15);
        --shadowSecondary: 1px 3px 6px rgba(0, 0, 0, 0.16);
        --shadowTertiary: 1px 1px 6px rgba(0, 0, 0, 0.16);
        --shadowItemSelect: 0px 0px 8px rgba(0, 0, 0, 0.25);
        --shadowItemNoSelect: 0px 0px 6px rgba(0, 0, 0, 0.25);


        --defaultTextColor:#f3f3f3;

    }
}

:root {
    .light_theme {
            // color
            --dark: #171717;
            --darkOpacity: #1717175c;
            --grey: #5d5d63;
            --greyOpacity: #66666646;
            --blueLight: #72adf2;
            --whiteOpcaity: #ffffffcc;
            --light: #f3f3f3;
            --lightOpacity: #f3f3f3cc;
            --blue: #024f5d;
            --blueOpacity: #024f5d30;
            --yellow: #e3a719;
            --red: #e05a49;
            --redOpacity: #e05a4980;
            --green: #34a76e;
            --greenOpacity: #34a76e80;
            --orange: #E77F3E;

            // opacity
            --opacityPrimary: 0.7;
            --opacitySecondary: 0.5;
            --opacityTertiary: 0.3;

            // radius
            --radiusPrimary: 20px;
            --radiusSecondary: 14px;
            --radiusTertiary: 7px;

            // shadow
            --shadowPrimary: 1px 1px 8px rgba(0, 0, 0, 0.15);
            --shadowSecondary: 1px 3px 6px rgba(0, 0, 0, 0.16);
            --shadowTertiary: 1px 1px 6px rgba(0, 0, 0, 0.16);
            --shadowItemSelect: 0px 0px 8px rgba(0, 0, 0, 0.25);
            --shadowItemNoSelect: 0px 0px 6px rgba(0, 0, 0, 0.25);
        --defaultTextColor:#f3f3f3;

    }
}
