._pt-9 {
    padding-top: 6rem  !important;
    padding-bottom: 0rem  !important;
}

.h-20 {
    height: 20% !important;
}

.h-10 {
    height: 10% !important;
}

.h-5 {
  height: 5% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-48 {
  height: 48.5% !important;
}

.h-60 {
  height: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.h-15 {
    height: 15% !important;
}

.h-55{
  height: 55% !important;
}

.h-65 {
    height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}


.h-90 {
  height: 90% !important;
}

._h-95 {
  height: 95% !important;
}

._h-95 {
  height: 90% !important;
}

._h-92 {
  height: 92.25% !important;
}


._z-i-1{
z-index: 1 !important;
}
._z-i-2{
  z-index: 20 !important;
  }


  .pt-circle-counting{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    padding-top: 3.75rem !important;
  }


  ._striped_zebra:nth-child(even){
      background-color: #f2f2f2 !important;
    }
