app-nested-card-default {
  height: 100%;
  max-height: 100%;
  width: 100%;
    margin: auto;
    @extend .p-3;
}

app-action-bar {
    width: 100%;
}

app-nav-bar-tabs {
    width: 100%;
}

app-layout-without-data,app-layout-data{
  max-height: 100% !important;
  min-height: 100% !important;
  height: 100% !important;
  display: flex !important;
}

app-edit-favorite-localbusiness{
  height: inherit !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

app-detail-nav {
    width: 100%;
    overflow: hidden;

    .swiper-slide {
        // width: 70px !important;
        height: 40px;
        opacity: var(--opacityTertiary);
        box-shadow: var(--shadowItemNoSelect);
    }

    .swiper-slide-prev,
    .swiper-slide-next {
        opacity: var(--opacityPrimary);
    }

    .swiper-slide-active {
        opacity: 1;
        box-shadow: var(--shadowItemSelect);
    }
}

.nav-item-icon{
    padding: 14px 14px 6px 14px;
}

._data_main_important{
  font-weight: 500 !important;
  >*{
  font-size: 1.75rem !important;

  }
}
._data_main_variation{
  font-weight: 500 !important;
  >*{
  font-size: 1.30rem !important;

  }
}


._modal_funnel_important{
  font-weight: 500 !important;
  >*{
  font-size: 1.2rem !important;

  }
}

._modal_funnel_second{
  font-weight: 500 !important;
  >*{
  font-size:1rem !important;

  }
}

._modal_funnel_variation{
  font-weight: 500 !important;
  >*{
  font-size: 1.2rem !important;

  }
}

._data_main_variation_small{
  font-weight: 500 !important;
  >*{
  font-size: 1rem !important;

  }
}
._data_main_versus{
  font-weight: 500 !important;
  >*{
    font-size: 1.25rem !important;
    }
}


app-journey-table{
  table{
    width: 100% !important;
    color: white !important;
    th, td{
      width: 17.5%;
      font-size: .8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px 1px !important;
      border-right: none;
    }

    tr{
      border-bottom: none;
    }

    th.largeSize, td.largeSize{
      width: 25%;
      font-size: .7rem;
    }

    th.smallSize, td.smallSize{
      width: 5%;
    }

    td.negativeContent{
      background: rgb(188,70,150) !important;
      background: -moz-linear-gradient(132deg, rgba(188,70,150,1) 0%, rgba(229,59,22,1) 100%) !important;
      background: -webkit-linear-gradient(132deg, rgba(188,70,150,1) 0%, rgba(229,59,22,1) 100%) !important;
      background: linear-gradient(132deg, rgba(188,70,150,1) 0%, rgba(229,59,22,1) 100%) !important;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bc4696",endColorstr="#e53b16",GradientType=1) !important;
    }

    td.positiveContent{
      background: rgb(8,155,68) !important;
      background: -moz-linear-gradient(132deg, rgba(8,155,68,1) 0%, rgba(72,107,142,1) 100%) !important;
      background: -webkit-linear-gradient(132deg, rgba(8,155,68,1) 0%, rgba(72,107,142,1) 100%) !important;
      background: linear-gradient(132deg, rgba(8,155,68,1) 0%, rgba(72,107,142,1) 100%) !important;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#089b44",endColorstr="#486b8e",GradientType=1) !important;
    }

    td.neutralContent{
      background: rgb(90,90,90);
      background: -moz-linear-gradient(135deg, rgba(90,90,90,1) 0%, rgba(0,0,0,1) 100%);
      background: -webkit-linear-gradient(135deg, rgba(90,90,90,1) 0%, rgba(0,0,0,1) 100%);
      background: linear-gradient(135deg, rgba(90,90,90,1) 0%, rgba(0,0,0,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5a5a5a",endColorstr="#000000",GradientType=1);
    }
  }
}

app-action-bar{
  @media screen and (max-height: 750px){
      .action_info .actual_business{
          font-size: .9rem;
          line-height: 12px;
      }

      .action_info .data_type{
          font-size: .8rem;
      }
  }

  @media screen and (max-height: 600px){
    .action_info .actual_business{
        font-size: .8rem;
        line-height: 8px;
    }

    .action_info .data_type{
        font-size: .7rem;
    }
}
}

app-understand-colors{
  .positive{
    .gradient{
      content: " ";
      height: 30px;
      background: rgb(72,107,142);
      background: linear-gradient(135deg, rgba(72,107,142,1) 0%, rgba(8,155,68,1) 100%);
      border-radius: 5px;
    }
    b{
      color: green;
    }
  }

  .neutral{
    .gradient{
      content: " ";
      height: 30px;
      background: rgb(242,222,63);
      background: linear-gradient(135deg, rgba(242,222,63,1) 0%, rgba(231,125,62,1) 100%);
      border-radius: 5px;
    }
    b{
      color: orange;
    }
  }

  .negative{
    .gradient{
      content: " ";
      height: 30px;
      background: rgb(188,70,150);
      background: linear-gradient(135deg, rgba(188,70,150,1) 0%, rgba(229,59,22,1) 100%);
      border-radius: 5px;
    }
    b{
      color: red;
    }
  }

}

.dropdownBasic{
  border-radius: var(--radiusSecondary) !important;
}

// custom modal for calandar select
.custom-modal-calendar-calendar, .custom-modal-calendar-type{
  background-color: rgba($color: #000000, $alpha: .5) !important;
  .modal-content {
    margin-left: 50%;
    background-color: transparent !important;
    border: none;
    width: 50%;
  }
}
.custom-modal-calendar-calendar {
  .modal-content {
    margin-top: 10px;
  }
}

.custom-modal-calendar-type {
  .modal-content {
    margin-top: 57px;
  }
}
// custom modal for calandar select
.custom-modal-calendar-calendar, .custom-modal-calendar-type{
  background-color: rgba($color: #000000, $alpha: .5) !important;
  .modal-content {
    margin-left: 50%;
    background-color: transparent !important;
    border: none;
    width: 50%;
  }
}
.custom-modal-calendar-calendar {
  .modal-content {
    margin-top: 10px;
  }
}

.custom-modal-calendar-type {
  .modal-content {
    margin-top: 57px;
  }
}