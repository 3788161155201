/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'pikaday/css/pikaday.css';
@import 'swiper/css/bundle';
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import './assets/scss/index.scss';

*{
    padding: 0;
    margin: 0;
    font-family: Helvetica;
    outline: 0;
}

$min_width: 320px;
$max_width: 1500px;
$min_font: 17px;
$max_font: 26px;

html,
body {
  height: 100%;
  min-height: 100%;
  min-height: -webkit-fill-available;
  position: relative;
  width: auto;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: transparent !important;
}

app-root {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  position: relative;
}

.dropdown-toggle::after {
  display:none !important;
}

.dark{color: var(--dark)  !important;}
.darkOpacity{color: var(--darkOpacity) !important;}
.grey{color: var(--grey) !important;}
.blueLight{color: var(--blueLight) !important;}
.light{color: var(--light) !important;}
.lightOpacity{color: var(--lightOpacity) !important;}
.blue{color: var(--blue) !important;}
.yellow{color: var(--yellow) !important;}
.red{color: var(--red) !important;}
.redOpacity{color: var(--redOpacity) !important;}
.green{color: var(--green) !important;}
.greenOpacity{color: var(--greenOpacity) !important;}
.orange{color: var(--orange) !important}



.bg-dark{background: var(--dark);}
.bg-darkOpacity{background: var(--darkOpacity);}
.bg-grey{background: var(--grey);}
.bg-greyOpacity{background: var(--greyOpacity);}
.bg-blueLight{background: var(--blueLight);}
.bg-light{background: var(--light);}
.bg-lightOpacity{background: var(--lightOpacity);}
.bg-blue{background: var(--blue);}
.bg-yellow{background: var(--yellow);}
.bg-red{background: var(--red);}
.bg-redOpacity{background: var(--redOpacity);}
.bg-green{background: var(--green);}
.bg-greenOpacity{background: var(--greenOpacity);}

.shadow-primary{box-shadow: var(--shadowPrimary);}
.shadow-secondary{box-shadow: var(--shadowSecondary);}
.shadow-tertiary{box-shadow: var(--shadowTertiary);}
.shadow-item-select{box-shadow: var(--shadowItemSelect);}
.shadow-item-no-select{box-shadow: var(--shadowItemNoSelect);}

.icon-marge{
    margin: 15px 15px 15px 15px;
}

.radius-primary{border-radius: var(--radiusPrimary);}
.radius-secondary{border-radius: var(--radiusSecondary);}
.radius-tertiary{border-radius: var(--radiusTertiary);}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid black;
  -webkit-text-fill-color: var(--dark);
  transition: background-color 5000s ease-in-out 0s;
}
