.basic_radiant {
    height: 100%;
    width: 100%;
    background-image: url(/assets/svg/background/bg-basic.svg);
    background-repeat: no-repeat;
    z-index: -1;
}

.positive_radiant {
    height: 100%;
    width: 100%;
    background-image: url(/assets/svg/background/bg-positive.svg);
    background-repeat: no-repeat;
    z-index: -1;
}

.negative_radiant {
    height: 100%;
    width: 100%;
    background-image: url(/assets/svg/background/bg-negative.svg);
    background-repeat: no-repeat;
    z-index: -1;
}


.theme_dark {
  background: black;
  background-color: black;
  height: 100%;
  width: 100%;
  z-index: -1;
}
