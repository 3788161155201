.card_basic {
      border-radius: var(--radiusPrimary);
      box-shadow: var(--shadowPrimary);

      @extend .bg-lightOpacity
}

.card_content{
  height: 100% !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
}
