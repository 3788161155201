.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

._mh-5 {
    min-height: 5rem  !important;
}

.transform-rotate180{
    transform: rotate(180deg);
}

.pointer{
  cursor: pointer !important;
}

.no-scrollable{
    overflow: hidden;
}

.bg-tricks{
    background-color: #E6E6E6;
    border-radius: 15px;

}