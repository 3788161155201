
.modal{
  overflow-y: hidden !important;
}

.localbusinesses-modal-content {
    margin-top: 5rem  !important;
    .modal-content {
        height: 75.55% !important;
        overflow-y: scroll !important;
        padding: 0.35rem !important;
        border-radius: 25px;
      }
}

app-modal-localbusinesses-search{
  overflow-y: scroll !important;
}
