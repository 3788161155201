
@media screen and (max-height: 600px) {
    .ref-data-main{
        font-size: 1.5rem !important;
        line-height: 1.5rem;
    }

    .comp-data-main{
        font-size: .9rem;
        line-height: 1rem;
    }

    .variation-main{
        font-size: .9rem !important;
    }
}