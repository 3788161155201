*{
  font-size: 1rem;
}

h1{
    font-size: 1.4rem  !important;
    font-weight: bold !important;
    line-height: 0.625rem !important;
    color: var(--dark);
}

.text{
    color: var(--dark);
    font-size: 1.4rem;

    small{
        font-size: 0.7rem;
    }
    .label{
        font-size: 0.9rem;
        line-height: 21px;
    }
    .labelXAxis{
        font-size: 0.7rem;
        color: var(--yellow);
        font-weight: bold !important;
    }
    .labelXAxisVs{
        font-size: 0.6rem;
        color: var(--blue);
        font-weight: bold !important;

    }

    i.info{
        font-size: .8rem;
        color: white;
    }
}

.indic{
    font-size: .9rem;
    font-weight: lighter;
    font-style: italic;
    color: var(--dark);

    .small{
        font-size: .8rem;
    }
}

.ref-data{
    font-size: 1rem  !important;
    font-weight: lighter;
    color: var(--yellow);
}

.ref-data-main{
    font-size: 2.2rem  !important;
    color: var(--yellow);
    line-height: 2.1rem;
}

.ref-data-main-small{
    font-size: 1.3rem  !important;
    color: var(--yellow);
    line-height: 0.625rem;
}

.comp-data{
    line-height: 0.625rem;
    font-size: 0.9rem;
    color: var(--blue);
}

.comp-data-main{
    font-size: 1rem;
    color: var(--blue);
    line-height: 1.5rem;
}

.ref-text{
    font-size: .95rem;
    color: var(--yellow)
}

.comp-text{
    font-size: 0.9rem;
    color: var(--blue);
}

.variation-positive{
    color: var(--green) !important;
    background-color: var(--greenOpacity) !important;
}

.variation-negative{
    color: var(--red) !important;
    background-color: var(--redOpacity) !important;
}

.variation-neutral{
    color: var(--black);
    background-color: var(--greyOpacity);
}

.variation-main-positive{
    color: var(--green) !important;
}

.variation-main-negative{
    color: var(--red) !important;
}

.variation-journey-table{
    font-size: 0.7rem;
}

h3{
    font-size: .9rem  !important;
    font-weight: bold !important;
    color: var(--dark) !important;
}

.nav-link{
    color: var(--defaultTextColor) !important;
    font-size: 0.9rem;
    padding: 0 !important;
    text-align: center;
}

.activ-nav{
  background: -moz-linear-gradient(90deg, rgba(7,157,57,1) 0%, rgba(18,145,128,1) 16%, rgba(15,148,108,1) 28%, rgba(220,17,129,1) 52%, rgba(229,59,22,1) 68%, rgba(229,59,22,1) 86%, rgba(213,146,20,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(7,157,57,1) 0%, rgba(18,145,128,1) 16%, rgba(15,148,108,1) 28%, rgba(220,17,129,1) 52%, rgba(229,59,22,1) 68%, rgba(229,59,22,1) 86%, rgba(213,146,20,1) 100%);
  background: linear-gradient(90deg, rgba(7,157,57,1) 0%, rgba(18,145,128,1) 16%, rgba(15,148,108,1) 28%, rgba(220,17,129,1) 52%, rgba(229,59,22,1) 68%, rgba(229,59,22,1) 86%, rgba(213,146,20,1) 100%);
  -webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#079d39",endColorstr="#d59214",GradientType=1);
}


.long-text{
    color:var(--grey);
    font-size: 0.9rem;
    a{
        color: var(--blueLight);
        text-decoration: underline;
    }
}

._disabled{
  color: var(--greyOpacity) !important;
}

table{
    p{
        font-size: .8rem;
        margin: 0;
    }
}

p.italic-small{
    font-style: italic;
    font-size: .8rem;;
}

app-dropdown-default{
    .boxName{
        font-size: 1.2rem;
        line-height: 0;
    }
    .mapName{
        font-size: 0.9rem;
        line-height: 0;
    }
}

.warning{
    font-size: .9rem;
}